.list-wrapper {
  display: flex;
  flex-direction: row;
}

.list-wrapper .left-list {
  flex: 0 0 400px;
  border-right: 2px solid #ccc;
}

.list-wrapper .list {
  clear: both;
  padding: 1rem;
}

.list-wrapper .list-selected {
  border-left: 4px solid #ccc;
}

.list-header {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.list-save {
  margin-left: auto;
}
